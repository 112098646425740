import React, { useEffect, useState } from "react";
import { Form, Input, Button, Switch, InputNumber, Upload } from "antd";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { useCookies } from "react-cookie";

//Components
import PageTitle from "../../../Components/PageTitle";
import { InboxOutlined } from "@ant-design/icons";
import Loader from "../../../Components/Generals/Loader";

//Actions
import { tinymceAddPhoto } from "../../../redux/actions/imageActions";
import * as actions from "../../../redux/actions/factActions";

// Lib
import base from "../../../base";
import axios from "../../../axios-base";
import { toastControl } from "src/lib/toasControl";
import { convertFromdata } from "../../../lib/handleFunction";

const requiredRule = {
  required: true,
  message: "Тус талбарыг заавал бөглөнө үү",
};

const { Dragger } = Upload;

const Edit = (props) => {
  const [form] = Form.useForm();
  const [cookies] = useCookies(["language"]);
  const [checkedRadio, setCheckedRadio] = useState({
    status: true,
  });
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState({
    visible: false,
    message: "",
  });

  // -- MODAL STATE

  // FUNCTIONS
  const init = () => {
    props.getFact(props.match.params.id);
  };

  const clear = () => {
    props.clear();
    form.resetFields();
    setLoading(false);
  };

  const handleChange = (event) => {
    form.setFieldsValue({ details: event });
  };

  const handleEdit = (values, status = null) => {
    const data = {
      ...values,
    };

    if (status === "draft") data.status = false;

    const sendData = convertFromdata(data);
    props.updateFact(props.match.params.id, sendData);
  };

  const handleRadio = (value, type) => {
    setCheckedRadio((bc) => ({ ...bc, [type]: value }));
  };

  // USEEFFECT
  useEffect(() => {
    init();
    return () => clear();
  }, []);

  useEffect(() => {
    if (props.fact) {
      const languageData = {
        name: !props.fact[cookies.language]
          ? ""
          : props.fact[cookies.language].name,
      };

      form.setFieldsValue({ ...props.fact, ...languageData });

      setCheckedRadio(() => ({
        status: props.fact.status,
      }));
    }
  }, [props.fact]);

  useEffect(() => {
    setLoading({ visible: props.loading, message: "Түр хүлээнэ үү" });
  }, [props.loading]);

  useEffect(() => {
    props.getFact(props.match.params.id);
  }, [cookies.language]);

  // Ямар нэгэн алдаа эсвэл амжилттай үйлдэл хийгдвэл энд useEffect барьж аваад TOAST харуулна
  useEffect(() => {
    toastControl("error", props.error);
  }, [props.error]);

  useEffect(() => {
    if (props.success) {
      clear();
      toastControl("success", "Амжилттай засагдлаа");
      setTimeout(() => {
        props.history.replace("/facts");
      }, 1000);
    }
  }, [props.success]);

  return (
    <>
      <div className="content-wrapper">
        <PageTitle
          name={`Туршилга тоон сургалт засварлах (${cookies.language})`}
        />
        <div className="page-sub-menu"></div>
        <div className="content">
          <Loader show={loading.visible}> {loading.message} </Loader>
          <div className="container-fluid">
            <Form layout="vertical" form={form}>
              <div className="row">
                <div className="col-8">
                  <div className="card card-primary">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <Form.Item
                            label="Туршилга"
                            name="name"
                            rules={[requiredRule]}
                            hasFeedback
                          >
                            <Input placeholder="Туршилга оруулна уу" />
                          </Form.Item>
                        </div>
                        <div className="col-12">
                          <Form.Item
                            label="Тоон үзүүлэлт"
                            name="factCount"
                            rules={[requiredRule]}
                            hasFeedback
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              placeholder="Тоон үзүүлэлт оруулна уу"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="card">
                    <div class="card-header">
                      <h3 class="card-title">ТОХИРГОО</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <Form.Item label="Идэвхтэй эсэх" name="status">
                            <Switch
                              checkedChildren="Идэвхтэй"
                              unCheckedChildren="Идэвхгүй"
                              size="medium"
                              checked={checkedRadio.status}
                              onChange={(checked) =>
                                handleRadio(checked, "status")
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="control-bottons">
                        <Button
                          key="submit"
                          htmlType="submit"
                          className="add-button"
                          loading={props.loading}
                          onClick={() => {
                            form
                              .validateFields()
                              .then((values) => {
                                handleEdit(values);
                              })
                              .catch((info) => {
                                // console.log(info);
                              });
                          }}
                        >
                          Шинэчлэх
                        </Button>
                        <Button
                          key="draft"
                          type="primary"
                          onClick={() => {
                            form
                              .validateFields()
                              .then((values) => {
                                handleEdit(values, "draft");
                              })
                              .catch((info) => {
                                // console.log(info);
                              });
                          }}
                        >
                          Ноороглох
                        </Button>
                        <Button onClick={() => props.history.goBack()}>
                          Буцах
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    success: state.factReducer.success,
    error: state.factReducer.error,
    loading: state.factReducer.loading,
    fact: state.factReducer.fact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tinymceAddPhoto: (file) => dispatch(tinymceAddPhoto(file)),
    updateFact: (id, data) => dispatch(actions.updateFact(id, data)),
    getFact: (id) => dispatch(actions.getFact(id)),
    clear: () => dispatch(actions.clear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
