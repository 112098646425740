import axios from "../../axios-base";

const errorBuild = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clear = () => {
  return {
    type: "CLEAR_BUSINESS",
  };
};

// SAVE BUSINESS
export const saveBusinessInit = () => {
  return {
    type: "CREATE_BUSINESS_INIT",
  };
};

export const saveBusiness = (business) => {
  return function (dispatch, getState) {
    dispatch(saveBusinessStart());
    axios
      .post(`/business`, business)
      .then((response) => {
        const result = response.data;
        dispatch(saveBusinessSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(saveBusinessError(resError));
      });
  };
};

export const saveBusinessStart = () => {
  return {
    type: "CREATE_BUSINESS_START",
  };
};

export const saveBusinessSuccess = (result) => {
  return {
    type: "CREATE_BUSINESS_SUCCESS",
    business: result,
  };
};

export const saveBusinessError = (error) => {
  return {
    type: "CREATE_BUSINESS_ERROR",
    error,
  };
};

// Excel practices
export const getExcelData = (query) => {
  return function (dispatch) {
    dispatch(getExcelDataStart());
    axios
      .get("business/excel?" + query)
      .then((response) => {
        const data = response.data.data;
        dispatch(getExcelDataSuccess(data));
      })
      .catch((error) => {
        let resError = errorBuild(error);
        dispatch(getExcelDataError(resError));
      });
  };
};

const getExcelDataStart = () => {
  return {
    type: "GET_BUSINESS_EXCELDATA_START",
  };
};

const getExcelDataSuccess = (data) => {
  return {
    type: "GET_BUSINESS_EXCELDATA_SUCCESS",
    excel: data,
  };
};

const getExcelDataError = (error) => {
  return {
    type: "GET_BUSINESS_EXCELDATA_ERROR",
    error,
  };
};

// LOAD PRACTICES

export const loadBusiness = (query = "") => {
  return function (dispatch, getState) {
    dispatch(loadBusinessStart());
    axios
      .get("business?" + query)
      .then((response) => {
        const businessies = response.data.data;
        const pagination = response.data.pagination;
        dispatch(loadBusinessSuccess(businessies));
        dispatch(loadPagination(pagination));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(loadBusinessError(resError));
      });
  };
};

export const loadBusinessStart = () => {
  return {
    type: "LOAD_BUSINESSIES_START",
  };
};

export const loadBusinessSuccess = (businessies, pagination) => {
  return {
    type: "LOAD_BUSINESSIES_SUCCESS",
    businessies,
    pagination,
  };
};

export const loadBusinessError = (error) => {
  return {
    type: "LOAD_BUSINESSIES_ERROR",
    error,
  };
};

export const loadPagination = (pagination) => {
  return {
    type: "LOAD_PAGINATION",
    pagination,
  };
};

export const deleteMultBusiness = (ids) => {
  return function (dispatch, getState) {
    dispatch(deleteMultStart());
    axios
      .delete("business/delete", { params: { id: ids } })
      .then((response) => {
        const deleteBusiness = response.data.data;
        dispatch(deleteBusinessSuccess(deleteBusiness));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(deleteBusinessError(resError));
      });
  };
};

export const deleteMultStart = () => {
  return {
    type: "DELETE_MULT_BUSINESS_START",
  };
};

export const deleteBusinessSuccess = (deleteData) => {
  return {
    type: "DELETE_MULT_BUSINESS_SUCCESS",
    deleteData,
  };
};

export const deleteBusinessError = (error) => {
  return {
    type: "DELETE_MULT_BUSINESS_ERROR",
    error,
  };
};

// GET PRACTICES

export const getInit = () => {
  return {
    type: "GET_BUSINESS_INIT",
  };
};

export const getBusiness = (id) => {
  return function (dispatch, getState) {
    dispatch(getBusinessStart());
    axios
      .get("business/" + id)
      .then((response) => {
        const business = response.data.data;
        dispatch(getBusinessSuccess(business));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getBusinessError(resError));
      });
  };
};

export const getBusinessStart = () => {
  return {
    type: "GET_BUSINESS_START",
  };
};

export const getBusinessSuccess = (business) => {
  return {
    type: "GET_BUSINESS_SUCCESS",
    business,
  };
};

export const getBusinessError = (error) => {
  return {
    type: "GET_BUSINESS_ERROR",
    error,
  };
};

//UPDATE BUSINESS

export const updateBusiness = (id, data) => {
  return function (dispatch) {
    dispatch(updateBusinessStart());
    axios
      .put(`business/${id}`, data)
      .then((response) => {
        const result = response.data;
        dispatch(updateBusinessSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(updateBusinessError(resError));
      });
  };
};

export const updateBusinessStart = () => {
  return {
    type: "UPDATE_BUSINESS_START",
  };
};

export const updateBusinessSuccess = (result) => {
  return {
    type: "UPDATE_BUSINESS_SUCCESS",
    updateBusiness: result,
  };
};

export const updateBusinessError = (error) => {
  return {
    type: "UPDATE_BUSINESS_ERROR",
    error,
  };
};

export const getCountBusiness = () => {
  return function (dispatch) {
    dispatch(getCountBusinessStart());

    axios
      .get(`business/count`)
      .then((response) => {
        const result = response.data.data;
        dispatch(getCountBusinessSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getCountBusinessError(resError));
      });
  };
};

export const getCountBusinessStart = () => {
  return {
    type: "GET_COUNT_BUSINESS_START",
  };
};

export const getCountBusinessSuccess = (result) => {
  return {
    type: "GET_COUNT_BUSINESS_SUCCESS",
    businessCount: result,
  };
};

export const getCountBusinessError = (error) => {
  return {
    type: "GET_COUNT_BUSINESS_ERROR",
    error,
  };
};
