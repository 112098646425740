const base = {
  // siteUrl: "http://localhost:3000/",
  // baseUrl: "http://localhost:3001/",
  // cdnUrl: "http://localhost:1012/uploads/",
  // apiUrl: "http://localhost:1012/v1/api/",
  cdnUrl: "https://zzlawfirm.mn/uploads/",
  baseUrl: "https://admin.zzlawfirm.mn/",
  siteUrl: "https://azzlawfirm.mn/",
  apiUrl: "https://admin.zzlawfirm.mn/api/",
};

export default base;
