const initialState = {
  loading: false,
  error: null,
  success: null,
  practices: [],
  paginationLast: {},
  excelData: [],
  practice: {},
  //count
  countLoading: false,
  totalCount: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_PRACTICES":
      return {
        ...state,
        error: null,
        success: null,
      };

    case "LOAD_PRACTICES_START":
      return {
        ...state,
        loading: true,
        error: null,
        suceess: null,
        practices: [],
      };

    case "LOAD_PRACTICES_SUCCESS":
      return {
        ...state,
        loading: false,
        practices: action.practices,
      };

    case "LOAD_PRACTICES_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        practices: [],
        error: action.error,
      };

    case "LOAD_PAGINATION":
      return {
        ...state,
        paginationLast: action.pagination,
      };
    // EXCEL
    case "GET_PRACTICES_EXCELDATA_START":
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
        excelData: [],
      };

    case "GET_PRACTICES_EXCELDATA_SUCCESS":
      return {
        ...state,
        loading: false,
        excelData: action.excel,
      };

    case "GET_PRACTICES_EXCELDATA_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
        excelData: [],
      };

    // SAVE
    case "CREATE_PRACTICE_INIT":
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
      };

    case "CREATE_PRACTICE_START":
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };

    case "CREATE_PRACTICE_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        practices: action.practices,
        success: "Амжилттай нэмэгдлээ",
      };
    case "CREATE_PRACTICE_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "DELETE_MULT_PRACTICES_START":
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case "DELETE_MULT_PRACTICES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: "Амжилттай устгагдлаа",
        error: null,
      };
    case "DELETE_MULT_PRACTICES_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
      };

    //GET
    case "GET_PRACTICE_INIT":
      return {
        ...state,
        loading: false,
        success: null,
        error: null,
        practice: {},
      };

    case "GET_PRACTICE_START":
      return {
        ...state,
        loading: true,
        practice: {},
        error: null,
      };

    case "GET_PRACTICE_SUCCESS":
      return {
        ...state,
        loading: false,
        practice: action.practice,
        error: null,
      };

    case "GET_PRACTICE_ERROR":
      return {
        ...state,
        loading: false,
        practice: {},
        error: action.error,
      };
    //UPDATE
    case "UPDATE_PRACTICE_START":
      return {
        ...state,
        success: null,
        loading: true,
        error: null,
      };
    case "UPDATE_PRACTICE_SUCCESS":
      return {
        ...state,
        loading: false,
        success: "Мэдээллийг амжилттай шинэчлэгдлээ",
        error: null,
      };
    case "UPDATE_PRACTICE_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
      };
    case "UPDATE_END":
      return {
        ...state,
        loading: false,
        success: null,
        error: null,
      };

    // GET COUNT
    case "GET_COUNT_PRACTICES_START":
      return {
        ...state,
        countLoading: true,
        totalCount: null,
        error: null,
      };
    case "GET_COUNT_PRACTICES_SUCCESS":
      return {
        ...state,
        coutLoading: false,
        totalCount: action.practiceCount,
        error: null,
      };
    case "GET_COUNT_PRACTICES_ERROR":
      return {
        ...state,
        countLoading: false,
        totalCount: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
