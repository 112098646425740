import axios from "../../axios-base";

const errorBuild = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clear = () => {
  return {
    type: "CLEAR_PRACTICES",
  };
};

// SAVE PRACTICES
export const savePracticesInit = () => {
  return {
    type: "CREATE_PRACTICES_INIT",
  };
};

export const savePractices = (practices) => {
  return function (dispatch, getState) {
    dispatch(savePracticesStart());
    axios
      .post(`/practices`, practices)
      .then((response) => {
        const result = response.data;
        dispatch(savePracticesSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(savePracticesError(resError));
      });
  };
};

export const savePracticesStart = () => {
  return {
    type: "CREATE_PRACTICE_START",
  };
};

export const savePracticesSuccess = (result) => {
  return {
    type: "CREATE_PRACTICE_SUCCESS",
    practices: result,
  };
};

export const savePracticesError = (error) => {
  return {
    type: "CREATE_PRACTICE_ERROR",
    error,
  };
};

// Excel practices
export const getExcelData = (query) => {
  return function (dispatch) {
    dispatch(getExcelDataStart());
    axios
      .get("practices/excel?" + query)
      .then((response) => {
        const data = response.data.data;
        dispatch(getExcelDataSuccess(data));
      })
      .catch((error) => {
        let resError = errorBuild(error);
        dispatch(getExcelDataError(resError));
      });
  };
};

const getExcelDataStart = () => {
  return {
    type: "GET_PRACTICES_EXCELDATA_START",
  };
};

const getExcelDataSuccess = (data) => {
  return {
    type: "GET_PRACTICES_EXCELDATA_SUCCESS",
    excel: data,
  };
};

const getExcelDataError = (error) => {
  return {
    type: "GET_PRACTICES_EXCELDATA_ERROR",
    error,
  };
};

// LOAD PRACTICES

export const loadPractices = (query = "") => {
  return function (dispatch, getState) {
    dispatch(loadPracticesStart());
    axios
      .get("practices?" + query)
      .then((response) => {
        const practices = response.data.data;
        const pagination = response.data.pagination;
        dispatch(loadPracticesSuccess(practices));
        dispatch(loadPagination(pagination));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(loadPracticesError(resError));
      });
  };
};

export const loadPracticesStart = () => {
  return {
    type: "LOAD_PRACTICES_START",
  };
};

export const loadPracticesSuccess = (practices, pagination) => {
  return {
    type: "LOAD_PRACTICES_SUCCESS",
    practices,
    pagination,
  };
};

export const loadPracticesError = (error) => {
  return {
    type: "LOAD_PRACTICES_ERROR",
    error,
  };
};

export const loadPagination = (pagination) => {
  return {
    type: "LOAD_PAGINATION",
    pagination,
  };
};

export const deleteMultPractices = (ids) => {
  return function (dispatch, getState) {
    dispatch(deleteMultStart());
    axios
      .delete("practices/delete", { params: { id: ids } })
      .then((response) => {
        const deletePractices = response.data.data;
        dispatch(deletePracticesSuccess(deletePractices));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(deletePracticesError(resError));
      });
  };
};

export const deleteMultStart = () => {
  return {
    type: "DELETE_MULT_PRACTICES_START",
  };
};

export const deletePracticesSuccess = (deleteData) => {
  return {
    type: "DELETE_MULT_PRACTICES_SUCCESS",
    deletePractices: deleteData,
  };
};

export const deletePracticesError = (error) => {
  return {
    type: "DELETE_MULT_PRACTICES_ERROR",
    error,
  };
};

// GET PRACTICES

export const getInit = () => {
  return {
    type: "GET_PRACTICES_INIT",
  };
};

export const getPractice = (id) => {
  return function (dispatch, getState) {
    dispatch(getPracticeStart());
    axios
      .get("practices/" + id)
      .then((response) => {
        const practice = response.data.data;
        dispatch(getPracticeSuccess(practice));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getPracticeError(resError));
      });
  };
};

export const getPracticeStart = () => {
  return {
    type: "GET_PRACTICE_START",
  };
};

export const getPracticeSuccess = (practice) => {
  return {
    type: "GET_PRACTICE_SUCCESS",
    practice,
  };
};

export const getPracticeError = (error) => {
  return {
    type: "GET_PRACTICE_ERROR",
    error,
  };
};

//UPDATE PRACTICES

export const updatePractices = (id, data) => {
  return function (dispatch) {
    dispatch(updatePracticesStart());
    axios
      .put(`practices/${id}`, data)
      .then((response) => {
        const result = response.data;
        dispatch(updatePracticesSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(updatePracticesError(resError));
      });
  };
};

export const updatePracticesStart = () => {
  return {
    type: "UPDATE_PRACTICE_START",
  };
};

export const updatePracticesSuccess = (result) => {
  return {
    type: "UPDATE_PRACTICE_SUCCESS",
    updatePractices: result,
  };
};

export const updatePracticesError = (error) => {
  return {
    type: "UPDATE_PRACTICE_ERROR",
    error,
  };
};

export const getCountPractices = () => {
  return function (dispatch) {
    dispatch(getCountPracticesStart());

    axios
      .get(`practices/count`)
      .then((response) => {
        const result = response.data.data;
        dispatch(getCountPracticesSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getCountPracticesError(resError));
      });
  };
};

export const getCountPracticesStart = () => {
  return {
    type: "GET_COUNT_PRACTICES_START",
  };
};

export const getCountPracticesSuccess = (result) => {
  return {
    type: "GET_COUNT_PRACTICES_SUCCESS",
    orderCount: result,
  };
};

export const getCountPracticesError = (error) => {
  return {
    type: "GET_COUNT_PRACTICES_ERROR",
    error,
  };
};
