const initialState = {
  loading: false,
  error: null,
  success: null,
  businessies: [],
  paginationLast: {},
  excelData: [],
  business: {},
  //count
  countLoading: false,
  totalCount: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_BUSINESS":
      return {
        ...state,
        error: null,
        success: null,
      };

    case "LOAD_BUSINESSIES_START":
      return {
        ...state,
        loading: true,
        error: null,
        suceess: null,
        businessies: [],
      };

    case "LOAD_BUSINESSIES_SUCCESS":
      return {
        ...state,
        loading: false,
        businessies: action.businessies,
      };

    case "LOAD_BUSINESSIES_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        businessies: [],
        error: action.error,
      };

    case "LOAD_PAGINATION":
      return {
        ...state,
        paginationLast: action.pagination,
      };
    // EXCEL
    case "GET_BUSINESS_EXCELDATA_START":
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
        excelData: [],
      };

    case "GET_BUSINESS_EXCELDATA_SUCCESS":
      return {
        ...state,
        loading: false,
        excelData: action.excel,
      };

    case "GET_BUSINESS_EXCELDATA_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
        excelData: [],
      };

    // SAVE
    case "CREATE_BUSINESS_INIT":
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
      };

    case "CREATE_BUSINESS_START":
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };

    case "CREATE_BUSINESS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        business: action.business,
        success: "Амжилттай нэмэгдлээ",
      };
    case "CREATE_BUSINESS_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "DELETE_MULT_BUSINESS_START":
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case "DELETE_MULT_BUSINESS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: "Амжилттай устгагдлаа",
        error: null,
      };
    case "DELETE_MULT_BUSINESS_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
      };

    //GET
    case "GET_BUSINESS_INIT":
      return {
        ...state,
        loading: false,
        success: null,
        error: null,
        business: {},
      };

    case "GET_BUSINESS_START":
      return {
        ...state,
        loading: true,
        business: {},
        error: null,
      };

    case "GET_BUSINESS_SUCCESS":
      return {
        ...state,
        loading: false,
        business: action.business,
        error: null,
      };

    case "GET_BUSINESS_ERROR":
      return {
        ...state,
        loading: false,
        business: {},
        error: action.error,
      };
    //UPDATE
    case "UPDATE_BUSINESS_START":
      return {
        ...state,
        success: null,
        loading: true,
        error: null,
      };
    case "UPDATE_BUSINESS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: "Мэдээллийг амжилттай шинэчлэгдлээ",
        error: null,
      };
    case "UPDATE_BUSINESS_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
      };
    case "UPDATE_END":
      return {
        ...state,
        loading: false,
        success: null,
        error: null,
      };

    // GET COUNT
    case "GET_COUNT_BUSINESS_START":
      return {
        ...state,
        countLoading: true,
        totalCount: null,
        error: null,
      };
    case "GET_COUNT_BUSINESS_SUCCESS":
      return {
        ...state,
        coutLoading: false,
        totalCount: action.businessCount,
        error: null,
      };
    case "GET_COUNT_BUSINESS_ERROR":
      return {
        ...state,
        countLoading: false,
        totalCount: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
